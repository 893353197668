import { query } from "@solidjs/router";
import { gql } from "@solid-primitives/graphql";
import {
  getBuilderContent,
  getBuilderContentByModel,
  getBuilderContentById,
} from "~/utils/builder";

import { retrieveBuilderShopDetails } from "./gql";

export const getBuilderGlobalData = query(async () => {
  "use server";
  // ? from previous - caching necessary now?
  // const cache = await getCache("global");
  // if (cache !== null) return cache;

  const response = await fetch(
    `https://cdn.builder.io/api/v3/graphql/${
      import.meta.env.VITE_BUILDER_APIKEY
    }`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        variables: {},
        query: gql`
          query {
            header: symbol(query: { name: "Global Header" }) {
              id
              name
              modelId
              data {
                blocks
              }
            }
            footer: symbol(query: { name: "Global Footer" }) {
              id
              name
              modelId
              data {
                blocks
              }
            }
            announcements(limit: 200) {
              name
              data {
                copy
                buttonLabel
                buttonUrl
                linkAltText
              }
            }
            jobs(options: { includeUnpublished: false }) {
              name
            }
            maintenanceBlocker(options: { includeUnpublished: false }) {
              name
              data {
                active
                title
                description
                richText
              }
            }
            category(limit: 200) {
              name
              data {
                logo
              }
            }
            colour(limit: 200) {
              name
              data {
                colour
                gradient
              }
            }
          }
        `,
      }),
    }
  );

  if (response.ok) {
    const { data } = await response.json();
    const obj = {
      header: data.header[0],
      footer: data.footer[0],
      jobs: data.jobs.length,
      announcements: data.announcements,
      maintenanceBlocker: data?.maintenanceBlocker?.[0],
      categories: {} as Record<string, string>,
      colours: {} as Record<string, [string, boolean]>,
    };

    for (const category of data.category) {
      obj.categories[category.name] = category?.data?.logo;
    }
    for (const colour of data.colour) {
      obj.colours[colour.name] = [
        colour.data.colour,
        colour.data.gradient || false,
      ];
    }

    return obj;
  } else {
    throw new Error("Internal error.");
  }
}, "builder-global");

export const getBuilderPageData = query(async (path) => {
  return await getBuilderContent(path, "page");
}, "builder-pages");

export const getBuilderAhmEntries = query(async () => {
  return await getBuilderContentByModel("ahm", 999, 0);
}, "ahm-entries");

export const getBuilderCareerEntries = query(async () => {
  return await getBuilderContentByModel("jobs", 999, 0);
}, "career-entries");

export const getBuilderPressEntries = query(
  async (
    limit: number = 999,
    options: any = { sort: { "data.publishedDate": -1 } }
  ) => {
    return await getBuilderContentByModel("press", limit, 0, options);
  },
  "press-entries"
);

export const getBuilderPressEntry = query(async (slug: string) => {
  return await getBuilderContentById(slug, "press");
}, "press-entry");

export const getBuilderFAQEntry = query(async (slug: string) => {
  return await getBuilderContentById(slug, "faq");
}, "faq-entry");

export const getBuilderShopDetails = query(
  () => retrieveBuilderShopDetails(),
  "builder-shop-details"
);

export const promoCatDict: Record<string, string> = {
  all: "All",
  simplyRoma: "Simply Roma",
  romaOne: "Roma One",
  romaElite: "Rome Elite",
  romaContract: "Roma Contract",
  photoFrames: "Photo Frames",
  galleryFrames: "Gallery Frames",
  clearance: "Clearance",
  newReleases: "New Releases",
  leavingSoon: "Leaving Soon",
};
